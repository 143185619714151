<template>
  <div class="activity-all" ref="scroll" @scroll="handleScroll($event)">
    <MHeader title="站内信">
      <!-- <img
        src="@/assets/search/icon_qingchu2@2x.png"
        alt=""
        slot="right"
        @click="readComplete"
      /> -->
    </MHeader>
    <div class="activity-main m-padding">
      <div class="activity-item" v-for="(item, index) in msgList" :key="index">
        <div class="item-main">
          <div class="item-title">{{item.zh_name}}</div>
          <div class="link">
            <span>{{ item.created_at }}</span>
            <span style="margin-left: 10px">{{
              item.is_read == 1 ? "已读" : "未读"
            }}</span>
          </div>
          <div class="info-content">
            <p v-html="item.content_zh"></p>
          </div>
        </div>
      </div>
      <p
        v-if="noMore"
        class="search-no"
      >
        暂无更多...
      </p>
    </div>
    <van-loading
      v-show="loading"
      type="spinner"
      color="#ED2A24"
      size="6.4vw"
    ></van-loading>
  </div>
</template>

<script>
import MHeader from "@/components/zh/m-header.vue";
import { msg_news, read_complete } from "@/api/zh/message.js";
export default {
  components: { MHeader },
  data() {
    return {
      msgList: [],
      loading: false,
      noMore: false,
      page: 1,
      islock: false,
    };
  },
  methods: {
    // 内容滑动事件
    handleScroll(e) {
      this.offsetTop = e.target.scrollTop;

      if (
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <=
        50
      ) {
        if (!this.islock && !this.noMore) {
          e.preventDefault();
          e.stopPropagation();
          this.page++;
          this.getData();
        }
      }
    },
    getData() {
      this.loading = true;
      msg_news({ page: this.page }).then((res) => {
        this.loading = false;
        this.islock = false;
        if (res) {
          let reg = /#.*?#/;
          res.data.data.forEach((item) => {
            if (reg.test(item.content_zh) == true) {
              let content = item.content_zh.match(reg);
              let content_zh =
                item.content_zh.split(content[0])[0] +
                "<a style='color:#3278DB;' href=" +
                '"' +
                item.link_zh +
                '"' +
                ">" +
                content[0] +
                "</a>" +
                item.content_zh.split(content[0])[1];
              this.$set(item, "content_zh", content_zh);
            }
          });
          this.msgList = this.msgList.concat(res.data.data);
        } else {
          this.noMore = true;
          console.log(111)
        }
      });
    },
    readComplete() {
      read_complete().then((res) => {
        if (res) {
          this.getData();
          // this.$notify({ type: "success", message: '操作成功'});
        }
      });
    },
  },
  created() {
    // this.getData();
    this.readComplete();
  },
  activated() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.activity-all {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fafcff;
  .activity-main {
    margin-top: 44px;
    width: 100%;
    height: calc(100% - 44px);
    overflow-y: auto;
    .activity-item {
      width: 100%;
      margin-top: 20px;
      .item-main {
        width: 100%;
        padding: 16px 16px;
        background-color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        padding-bottom: 10px;
        overflow: hidden;
        .item-title {
          font-size: 17px;
        }
        img {
          width: 100%;
          height: 160px;
          border-radius: 4px;
        }
        .info-content {
          width: 100%;
        }
        p {
          padding: 0;
          &:nth-of-type(1) {
            margin-top: 5px;
            font-size: 14px;
            padding-top: 10px;
            font-weight: 500;
            color: rgb(83, 83, 83);
            letter-spacing: 0.5px;
            line-height: 21px;
          }
        }
        .link {
          margin-top: 6px;
          margin-right: 16px;
          > span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 20px;
          }
          .set-read {
            float: right;
            font-size: 12px;
            color: #0854bf;
          }
        }
      }
    }
  }
}
.search-no{
  line-height: 80px;
  text-align: center;
  color: #888;
  font-size: 14px;
}
</style>
