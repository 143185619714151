/** 
 *   消息
 */
 import { get } from '@/untils/js/axios.js'



//  物流交易 与 站内信 消息  全部已读( 刷子功能 )
export const news_all_red = params => get('/message/read-all', params)

//  物流消息与 站内信的消息数量（统计）
export const news_total = params => get('/message/statistics', params)

// 物流消息列表
export const ship_news = params => get('/message/logistics', params)

//  物流消息---单个消息--已读接口
export const ship_red = params => get('/ship_red', params)

// 站内信列表
export const msg_news = params => get('/message/site', params)

// 站内信--群体消息--已读接口
export const read_complete = params => get('/message/site-read-all', params)

// 站内信 单个消息已读
export const read_One = params => get('/sign', params)
